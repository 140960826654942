import axios from "axios";

export default {
    namespaced: true,
    state: {
        loaded: false,
        products: []
    },
    getters: {
        products: (state) => state.products,
        loaded: (state) => state.loaded
    },
    mutations: {
        products(state, products) {
            state.products = products
        },
        loaded(state, loaded) {
            state.loaded = loaded
        }
    },
    actions: {
        async loadProducts(context) {
            if (!context.getters["loaded"]) {
                const catalogUrl = process.env.VUE_APP_API_URL + "/catalog";
                // console.log('storeUrl: ' + storeUrl);
                const response = (await axios.get(catalogUrl, {
                    // headers: headers
                }));

                // console.log('catalog api response: ' + JSON.stringify(response, null, 2));

                const products = [];
                response.data.map((product) => {
                    // console.log('product: ' + JSON.stringify(product))

                    products.push({
                        "productId": product.productId,
                        "priceId": product.priceId,
                        "name": product.name,
                        "description": product.description,
                        "unitPrice": product.unitPrice,
                        "imageUrl": product.imageUrl,
                        "unitWeight": product.unitWeight
                    });
                });

                // console.log('catalog products: ' + JSON.stringify(products))
                await context.commit("products", products);
                /*context.commit("products", response.data.map((product) => {

                    console.log('product: ' + JSON.stringify(product))

                    return {
                        "productId": product.productId,
                        "priceId": product.priceId,
                        "name": product.name,
                        "description": product.description,
                        "unitPrice": product.unitPrice,
                        "imageUrl": product.imageUrl,
                        // "unitWeight": product.unitWeightOz
                    }
                }));*/
                await context.commit("loaded", true);
            }
        }
        /*async _fetchPrices(/!*context*!/) {
            return [
                {
                    id: "price_abc123",
                    unitprice: 9000
                },
            ];
        },
        async _fetchProducts(/!*context*!/) {
            return [
                {
                    id: "prod_QZYBp5CsL4Wn5q",
                    priceId: "price_abc123",
                    // taxId not shown in store - only used during checkout
                    taxId: "tax_abc123",
                    // shippingId not shown in store - only used during checkout
                    shippingId: "shipping_abc123",
                    name: 'MBL Book',
                    description: '110 Page Photo Book'
                }
            ];
        },
        async loadProducts(context) {

            const products = await context.dispatch("_fetchProducts");
            // console.log('fetchProducts: ' + JSON.stringify(products))
            const prices = await context.dispatch("_fetchPrices");
            // console.log('fetchPrices: ' + JSON.stringify(prices))

            const priced = products.map((product) => {
                // console.log('product[' + product.priceId + ']')
                const price = prices.find((price) => {
                    return price.id === product.priceId
                });

                if (price) {
                    /!*let up = price.unitprice > 0 ? price.unitprice / 100 : 0;*!/
                    return {
                        ...product,
                        price
                        /!*unitprice: up.toFixed(2)*!/
                    };
                }
            })
            context.commit("setProducts", priced);

            return priced;
        },*/
    },
    modules: {
    }
}

<template>
  <div class="container">
<!--    <h3 class="mb-5">Slideshow</h3>-->

    <div class="carousel slide mt-5"
         id="slider"
         data-bs-ride="carousel"
         data-bs-interval="5000"
         data-bs-pause="hover"
    >
      <div class="carousel-inner">
        <div v-for="(image, i) in images" :key="image" class="carousel-item" :class="i === 0 ? 'active' : ''">
          <img
              :src="image"
              alt=""
              class="d-block w-100"
          />
        </div>
      </div>

      <!-- Buttons -->
      <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#slider"
      >
        <span class="carousel-control-prev-icon"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#slider"
      >
        <span class="carousel-control-next-icon"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      images: [
        "/images/_76A0052.JPG",
        "/images/_76A3390.JPG",
        "/images/_76A5755_4_3.JPG",
        "/images/_76A8171_70_69.JPG",
        "/images/_76A8279_8_7.JPG",
        "/images/_Y3A3747_6_5.JPG",
        "/images/_Y3A7576_5_4.JPG",
      ]
    }
  },
}
</script>

<style>
.carousel-item {
  transition: transform 2s ease, opacity .5s ease-out
}
</style>
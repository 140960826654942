import { createStore } from 'vuex'
import cart from './cart'
import catalog from './catalog'

const store = createStore({
    modules: {
        cart: cart,
        catalog: catalog
    }
})

export default store;
<template>
  <div class="hello">
    <p class="display-4">Welcome to Moss Beach!</p>
    <p>
      A small coastal town in central California, Moss Beach is home to approximately 3500 residence and <a class="text-secondary" href="https://parks.smcgov.org/fitzgerald-marine-reserve" target="_blank" rel="noreferrer noopener">Fitzgerald Marine Reserve</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Welcome to the MossBeachLife!',
  props: {
    msg: String
  },
  mounted() {
    console.log('app mounted')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

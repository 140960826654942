<template>
  <nav class="navbar navbar-expand-md bg-dark navbar-dark">
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img src="/images/MBL-Logo3.png" alt="logo" height="40"> MossBeachLife
      </router-link>
      <button
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          class="navbar-toggler"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'home' ? 'active' : ''" aria-current="page" :to="{ name: 'home' }">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'gallery' ? 'active' : ''" aria-current="page" :to="{ name: 'gallery' }">Gallery</router-link>
          </li>
<!--          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'store' ? 'active' : ''" aria-current="page" :to="{ name: 'store' }">Store</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="pathClass('/checkout')" aria-current="page" :to="{ name: 'checkout-cart' }">Check Out</router-link>
          </li>-->

<!--          <li class="nav-item">
            <router-link class="nav-link" :class="pathClass('/bootstrap')" aria-current="page" :to="{ name: 'bootstrap-container' }">Bootstrap</router-link>
          </li>-->
<!--          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'contact' ? 'active' : ''" aria-current="page" :to="{ name: 'contact' }">Contact</router-link>
          </li>-->
<!--          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name == 'about' ? 'active' : ''" aria-current="page" :to="{ name: 'about' }">About</router-link>
          </li>-->
        </ul>
      </div>
<!--      <div class="navbar-brand">
        <div v-if="cartItems">
          <router-link to="/checkout/cart" class="nav-link active">
            <cart-heart-icon size="25"/>
          </router-link>
        </div>
        <div v-else>
          <cart-outline-icon size="25"  class="nav-link"/>
        </div>
      </div>-->
    </div>
  </nav>
  <router-view/>

<!--  <footer class="container-fluid fixed-bottom bg-transparent">-->
  <footer class="container-fluid bg-transparent">
    <div class="row">
      <div class="col-12">
        <p class="text-center pt-1 m-0"><copyright-icon size="15"/> MossBeachLife.com</p>
      </div>
    </div>
  </footer>
</template>

<script>
// import store from '@/store/store';
import CopyrightIcon from 'vue-material-design-icons/Copyright.vue';
// import CartOutlineIcon from 'vue-material-design-icons/CartOutline.vue';
// import CartHeartIcon from 'vue-material-design-icons/CartHeart.vue';

// https://github.com/robcresswell/vue-material-design-icons
// https://pictogrammers.com/library/mdi/
export default {
  components: {
    CopyrightIcon,
    // CartOutlineIcon,
    // CartHeartIcon
  },
  computed: {
    cartItems() {
      return this.$store.getters["cart/cart"].length > 0;
    }
  },
  methods: {
    pathClass(path) {
      const currentPath = this.$route.path;
      const active = currentPath.indexOf(path) >= 0;
      if (active) {
        return 'active';
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    /*&.router-link-exact-active {
      color: #42b983;
    }*/
  }
}
</style>

// import axios from "axios";

export default {
  namespaced: true,
  state: {
    cart: null,
  },
  getters: {
    cart: (state) => state.cart,
    /*async cart(state) {
      if (state.cart == null) {
        console.log('cart is null...');

        // attempt to load cart from local storage
        const localCart = localStorage.getItem('cart');
        if (localCart != null) {
          console.log('localCart found: ' + localCart);
          try {
            const cartObj = JSON.parse(localCart);
            console.log('cartObj: ' + JSON.stringify(cartObj));

            // Verify that cartObj is an array
            if (Array.isArray(cartObj)) {
              state.cart = cartObj;
            } else {
              console.error('Invalid cart format in local storage. Expected an array.');
              state.cart = [];
            }
          } catch (error) {
            console.error('Error parsing cart from local storage:', error);
            state.cart = [];
          }
        } else {
          console.log('localCart not found');
          state.cart = [];
        }
      }

      return state.cart;
    },*/
    /*calculatedCart(state) {
      return state.calculatedCart
    },*/
    /*intent(state) {
      return state.intent
    },*/
    /*clientSecret(state) {
      return state.clientSecret
    },*/
  },
  mutations: {
    cart(state, cart) {
      state.cart = cart
    },

    /*clientSecret(state, clientSecret) {
      state.clientSecret = clientSecret
    },
    intent(state, intent) {
      state.intent.id = intent.id
      state.intent.amount = intent.amount
    },
    calculatedCart(state, calculated) {
      state.calculatedCart.items.length = 0;
      calculated.items.forEach(item => {
        state.calculatedCart.items.push(item);
      })

      state.calculatedCart.subtotal = calculated.subtotal;
      state.calculatedCart.tax = calculated.tax;
      state.calculatedCart.shipping = calculated.shipping;
      state.calculatedCart.total = calculated.total;
    },*/
  },
  actions: {
    /*async calculateCart(context) {
      const cart = context.getters["cart"];
      const calculateUrl = process.env.VUE_APP_API_URL + "/calculate";
      const payload = {
        items: cart
      }
      const response = await axios.post(calculateUrl, payload, {
        // headers: headers
      });
      const calculated = response.data;
      console.log('calculate response: ' + JSON.stringify(calculated));
      context.commit("calculatedCart", calculated);
    },*/
    async loadCart(context) {
      let cart = context.getters["cart"];
      if (!cart) {
        const localCart = localStorage.getItem('cart');
        if (localCart != null) {
          //  for now ensure we have a clean slate, always
          localStorage.removeItem('cart');
          /*console.log('localCart found: ' + localCart);
          try {
            const cartObj = JSON.parse(localCart);
            console.log('cartObj: ' + JSON.stringify(cartObj));

            // Verify that cartObj is an array
            if (Array.isArray(cartObj)) {
              cart = cartObj;
              context.commit("cart", cart);
            } else {
              console.error('Invalid cart format in local storage. Expected an array.');
              cart = [];
              context.commit("cart", cart);
            }
          } catch (error) {
            console.error('Error parsing cart from local storage:', error);
            cart = [];
            context.commit("cart", cart);
          }*/
        } /*else {*/
          cart = [];
          context.commit("cart", cart);
        /*}*/
      }
    },
    async addToCartNew(context, cartItem) {
      await context.dispatch("loadCart");
      let cart = context.getters["cart"];

      // Find the item in the cart by productId
      const existingItem = cart.find(item => item.productId === cartItem.productId);

      if (existingItem) {

        //  if qty is different - adjust cart qty to match
        if (existingItem.qty !== cartItem.qty) {
          existingItem.qty = cartItem.qty;
        } else {
          // console.log('item already in cart and addQuantities match, so we ignore request')
          throw {
            errorType: 'AlreadyInCart',
            message: 'Item already in cart and addQuantities match',
            item: existingItem
          };
        }

        // If the item exists, update its quantity
        // existingItem.qty += cartItem.qty;
        //  todo: raise an error - ask user if they would like to increase quantity in cart
        // throw new Error('Item already in cart');
        /*throw {
          errorType: 'AlreadyInCart',
          message: 'Item already in cart',
          item: existingItem
        };*/
      } else {
        // If the item doesn't exist, add it to the cart
        console.log('adding to cart: ' + JSON.stringify(cartItem));
        cart.push(cartItem);
      }
    },
    /*async addToCart(context, item) {
      const qty = item.qty;
      const cart = context.getters["cart"];

      const existing = cart.find((cartItem) => {
        return cartItem.productId === item.productId;
      });

      if (existing) {
        existing.qty += qty;
      } else {
        cart.push(item)
      }

      // await context.dispatch("calculateCart")
    },*/
    async shippingOptions(_, items) {

      // todo: this should be an api call

      //   return all possible shipping options and costs, based on items in the cart
      const options = [];

      let weight = 0;
      items.forEach(item => {
        const qty = item.qty;
        const prod = item.product;
        const unitWeight = prod.unitWeight

        weight += (unitWeight * qty);

        // calculate total product weight
      });

      console.log('ship weight: ' + weight);

      let pounds = Math.ceil(weight / 16);
      if  (pounds < 2) {
        pounds = 2;
      } else if (pounds > 5) {
        pounds = 5;
      }

      switch (pounds) {
        case 2:
          options.push({
            name: 'usps_book',
            amount: '700'
          });
          options.push({
            name: 'usps_2day',
            amount: '1400'
          });

          break;
        case 3:
          options.push({
            name: 'usps_book',
            amount: '900'
          });
          options.push({
            name: 'usps_2day',
            amount: '1600'
          });
          break;
        case 4:
          options.push({
            name: 'usps_book',
            amount: '1100'
          });
          options.push({
            name: 'usps_2day',
            amount: '1800'
          });
          break;
        case 5:
          options.push({
            name: 'usps_book',
            amount: '1300'
          });
          options.push({
            name: 'usps_2day',
            amount: '2000'
          });
          break;
        default:
          options.push({
            name: 'usps_book',
            amount: '1300'
          });
          options.push({
            name: 'usps_2day',
            amount: '2000'
          });
          break;
      }

      return options;
    },

    // async prepareForCheckout(context) {
    //   const currency = 'usd';
    //   const items = []
    //   context.getters["cart"].forEach(cartItem => {
    //     items.push({
    //       productId: cartItem.productId,
    //       unitPrice: cartItem.unitPrice,
    //       qty: cartItem.qty
    //     })
    //   });
    //
    //   const payload = {
    //     currency,
    //     items
    //   }
    //   // console.log('prepare checkout: ' + JSON.stringify(payload))
    //
    //   const prepareCheckoutUrl = process.env.VUE_APP_API_URL + "/checkout/prep";
    //   const response = await axios.post(prepareCheckoutUrl, payload, {
    //     // headers: headers
    //   });
    //   // console.log('preparecheckout api response: ' + JSON.stringify(response.data, null, 2));
    //
    //   // const clientSecret = response.data.clientSecret;
    //   context.commit("clientSecret", response.data.clientSecret)
    //   context.commit("intent", {
    //     id: response.data.intent,
    //     amount: (response.data.amount > 0 ? response.data.amount / 100 : 0).toFixed(2)
    //   })
    // },
    async increment(context, productId) {
      const cart = context.getters["cart"];
      cart.forEach((item) => {
        if (item.productId === productId) {
          item.qty += 1;
        }
      });

      await context.dispatch("calculateCart")

      if (context.getters["intent"].id) {
        // we are checking out - update intent
        await context.dispatch("updateIntent")
      }

    },
    async decrement(context, productId) {
      const cart = context.getters["cart"];
      cart.forEach((item, idx) => {
        if (item.productId === productId) {
          item.qty -= 1;

          if (item.qty <= 0) {
            cart.splice(idx, 1);
          }
        }
      });

      await context.dispatch("calculateCart")

      if (context.getters["intent"].id) {
        // we are checking out - update intent
        await context.dispatch("updateIntent")
      }
    },
    async removeItem(context, productId) {
      const cart = context.getters["cart"];
      cart.forEach((item, idx) => {
        if (item.productId === productId) {
          cart.splice(idx, 1);
        }
      })

      await context.dispatch("calculateCart")

      const intent = context.getters["intent"];
      if (intent.id) {
        // we are checking out - update intent
        await context.dispatch("updateIntent")

        if (intent.amount === "0.00") {
          context.commit("intent", {
            id: null,
            amount: "0.00"
          })
        }
      }
    },
    // async updateIntent(context) {
    //   const intentId = context.getters["intent"].id;
    //   if (intentId) {
    //     const currency = 'usd';
    //     const items = []
    //     context.getters["cart"].forEach(cartItem => {
    //       items.push({
    //         productId: cartItem.productId,
    //         unitPrice: cartItem.unitPrice,
    //         qty: cartItem.qty
    //       })
    //     });
    //
    //     const payload = {
    //       intent: intentId,
    //       currency,
    //       items
    //     }
    //
    //     const prepareCheckoutUrl = process.env.VUE_APP_API_URL + "/checkout/prep";
    //     const response = await axios.post(prepareCheckoutUrl, payload, {
    //       // headers: headers
    //     });
    //     console.log('update checkout api response: ' + JSON.stringify(response.data, null, 2));
    //
    //     context.commit("intent", {
    //       id: intentId,
    //       amount: (response.data.amount > 0 ? response.data.amount / 100 : 0).toFixed(2)
    //     })
    //   }
    // },
    // async checkout(context) {
    //   const currency = 'usd';
    //   const intentId = context.getters["intent"].id;
    //   const items = []
    //   context.getters["cart"].forEach(cartItem => {
    //     items.push({
    //       productId: cartItem.productId,
    //       unitPrice: cartItem.unitPrice,
    //       qty: cartItem.qty
    //     })
    //   });
    //
    //   const payload = {
    //     intent: intentId,
    //     currency,
    //     items
    //   };
    //
    //   const prepareCheckoutUrl = process.env.VUE_APP_API_URL + "/checkout/order";
    //   const response = await axios.post(prepareCheckoutUrl, payload, {
    //     // headers: headers
    //   });
    //   console.log('checkout order api response: ' + JSON.stringify(response.data, null, 2));
    // },
    async emptyCart(context) {
      context.getters["cart"].length = 0;
      /*await context.commit("calculatedCart", {
        items: [],
        subtotal: "0.00",
        shipping: "0.00",
        total: "0.00"
      });
      await context.commit("clientSecret", null);
      await context.commit("intent", {
        id: null,
        amount: "0.00"
      });*/
    }
  }
}

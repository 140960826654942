<template>
  <div class="home">
    <HelloWorld msg="Welcome to MossBeachLife!"/>
    <SlideShow/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import SlideShow from "@/components/SlideShow.vue";

export default {
  name: 'HomeView',
  components: {
    SlideShow,
    HelloWorld,
  }
}
</script>

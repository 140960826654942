import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
/*import cart from './store/cart'
import catalog from './store/catalog'*/
import store from './store/store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

/*import { createStore } from 'vuex'

const store = createStore({
    modules: {
        cart: cart,
        catalog: catalog
    }
})*/

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
